.avatar,
.avatar-placeholder {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-size: 100%;
  box-shadow: 0 1px 1px rgba(0,0,0,.2)
}

.avatar-placeholder.large,
.avatar.large {
  width: 120px;
  height: 120px
}

.avatar-placeholder.mini,
.avatar.mini {
  width: 36px;
  height: 36px
}

.avatar-placeholder.header-avatar,
.avatar.header-avatar {
  width: 2em;
  height: 2em;
  background-color: #e7edf0
}

a.avatar::after {
  display: none;
  width: 0;
  height: 0
}

.avatar-placeholder {
  line-height: 62px;
  text-align: center;
  background-color: #f3f6f7;
  font-size: 1.3em
}

@media screen and (max-width: 500px) {
  .avatar,.avatar-placeholder {
    width: 84%;
    height: 84%;
    margin: 0 auto
  }
}
