body {
  margin: 0;
  background: #fff;
  font-size: 16px;
  /* font-family: Lato,Roboto,'Open Sans',Helvetica,sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  color: #090909;
  /* color: #323232 */
}

/* Legacy CSS */

.modal-close,b,optgroup,strong {
  font-weight: 700
}

.button-upload,svg:not(:root) {
  overflow: hidden
}

img,legend {
  border: 0
}

legend,td,th {
  padding: 0
}

.time-slider,select {
  -webkit-appearance: none;
  -moz-appearance: none
}

.button:focus,.time-slider:focus,a:active,a:hover,button:focus,select:active,select:focus {
  outline: 0
}

.modal-close,.txt-center {
  text-align: center
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%
}

article,aside,details,figcaption,figure,footer,header,hgroup,main,menu,nav,section,summary {
  display: block
}

audio,canvas,progress,video {
  display: inline-block;
  vertical-align: baseline
}

audio:not([controls]) {
  display: none;
  height: 0
}

[hidden],template {
  display: none
}

/* a {
  background-color: transparent
} */

abbr[title] {
  border-bottom: 1px dotted
}

dfn {
  font-style: italic
}

h1 {
  font-size: 2em;
  margin: .67em 0
}

mark {
  background: #ff0;
  color: #000
}

small {
  font-size: 80%
}

sub,sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sup {
  top: -.5em
}

sub {
  bottom: -.25em
}

figure {
  margin: 1em 40px
}

hr {
  box-sizing: content-box;
  height: 0
}

pre,textarea {
  overflow: auto
}

code,kbd,pre,samp {
  font-family: monospace,monospace;
  font-size: 1em
}

button,input,optgroup,select,textarea {
  color: inherit;
  font: inherit;
  margin: 0
}

button {
  overflow: visible
}

button,select {
  text-transform: none
}

button,html input[type=button],input[type=reset],input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer
}

button[disabled],html input[disabled] {
  cursor: default
}

button::-moz-focus-inner,input::-moz-focus-inner {
  border: 0;
  padding: 0
}

input {
  line-height: normal
}

input[type=checkbox],input[type=radio] {
  box-sizing: border-box;
  padding: 0
}

input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button {
  height: auto
}

input[type=search] {
  -webkit-appearance: textfield
}

input[type=search]::-webkit-search-cancel-button,input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

.card {
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0,0,0,.1)
}

* {
  box-sizing: border-box
}

#page,.container,body,html {
  height: 100%
}

.container.ensure-full-height {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.fw-section {
  width: 100%;
  padding: 2em 0
}

.fw-section.alt {
  background: #e7edf0
}

.content-container {
  max-width: 800px;
  margin: 1em auto;
  padding: 0 1em;
  -ms-flex-positive: 1;
  flex-grow: 1
}

.hidden {
  display: none
}

.button,.button-group,button,select {
  display: inline-block
}

p {
  line-height: 1.6em
}

.danger,.error {
  color: #ca2d2d
}

.muted {
  color: #969696
}

.brand {
  color: #01579b
}

/* a,a:visited {
  color: #01579b
} */

/* a,a:visited {
  position: relative;
  text-decoration: none;
  transition: all 250ms ease;
  cursor: pointer
}

a::after,a:visited::after {
  content: "";
  position: absolute;
  width: 96%;
  height: 1px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #01579b;
  opacity: 0;
  transition: all .3s ease-in-out 0s;
  transform: translateY(4px)
}

a:hover::after,a:visited:hover::after {
  opacity: 1;
  transform: translateY(2px)
} */

.button,button {
  padding: .3em 1em .4em;
  background-color: #e9eff2;
  border-radius: 20px;
  border: 1px solid transparent;
  font-size: 15px;
  line-height: 1.4em;
  color: #323232;
  text-decoration: none
}

.button+.button,.button+button,button+.button,button+button {
  margin-left: .2em
}

.button.small,button.small {
  font-size: 12px
}

.button.large,.material-icons.md-18,button.large {
  font-size: 18px
}

.button.material-icons,button.material-icons {
  height: 2em;
  width: 2em;
  padding: 0;
  border-radius: 160px
}

.button.clear,.button.hollow,button.clear,button.hollow {
  background-color: transparent
}

.button.clear:hover,.button.hollow:hover,button.clear:hover,button.hollow:hover {
  background-color: rgba(182,186,189,.2)
}

.button.hollow,button.hollow {
  border: 1px solid #90acba
}

.button.cta,button.cta {
  background-color: #016bbe;
  color: #fff
}

.button.cta.hollow,button.cta.hollow {
  border-color: transparent
}

.button.cta:hover,button.cta:hover {
  background-color: #01579b
}

.button.danger,button.danger {
  background-color: #d43d3d;
  color: #fff
}

.button.danger:hover,button.danger:hover {
  background-color: #ca2d2d
}

.button.danger[disabled]:hover,button.danger[disabled]:hover {
  background-color: #d43d3d
}

.button::after,button::after {
  content: none;
  width: inherit;
  height: inherit
}

.button:focus,button:focus {
  box-shadow: 0 0 4px rgba(0,0,0,.5)
}

.button:hover,button:hover {
  background-color: #d0dce2
}

.button[disabled],button[disabled] {
  opacity: .5
}

.button[disabled]:hover,button[disabled]:hover {
  background-color: inherit
}

.button.selected,button.selected {
  background-color: #cad7de
}

.button.twitter,button.twitter {
  padding-left: 2.8em;
  color: #fff;
  background-color: #55acee;
  background-image: url(/images/social/twitter-white-100x100.png);
  background-size: 28px;
  background-repeat: no-repeat;
  background-position: .6em 50%
}

.button.twitter:hover,button.twitter:hover {
  background-color: #3ea1ec
}

.button.gravatar,button.gravatar {
  padding-left: 2.6em;
  color: #fff;
  background-color: #1e8cbe;
  background-image: url(/images/social/gravatar-white-100x100.png);
  background-size: 28px;
  background-repeat: no-repeat;
  background-position: .6em 35%
}

.button.gravatar:hover,button.gravatar:hover {
  background-color: #1c81af
}

input[type=submit].button {
  border: 0
}

.button-group>.button,.button-group>button {
  border-radius: 0;
  border-right-width: 0
}

.button-group>.button+.button,.button-group>.button+button,.button-group>button+.button,.button-group>button+button {
  margin-left: 0
}

.button-group>.button:first-of-type,.button-group>button:first-of-type {
  padding-left: 1.1em;
  border-radius: 20px 0 0 20px
}

.button-group>.button:last-of-type,.button-group>button:last-of-type {
  padding-right: 1.1em;
  border-right-width: 1px;
  border-radius: 0 20px 20px 0
}

.button-upload {
  position: relative
}

.button-upload input[type=file] {
  position: absolute;
  top: 0;
  left: -100%;
  z-index: 5;
  height: 100%;
  width: 200%;
  opacity: 0;
  cursor: pointer
}

.material-icons.md-24 {
  font-size: 24px
}

.material-icons.md-36 {
  font-size: 36px
}

.material-icons.md-48 {
  font-size: 48px
}

.material-icons.md-dark {
  color: rgba(0,0,0,.54)
}

.material-icons.md-dark.md-inactive {
  color: rgba(0,0,0,.26)
}

.material-icons.md-light {
  color: #fff
}

.material-icons.md-light.md-inactive {
  color: rgba(255,255,255,.3)
}

.location-icon {
  position: relative;
  top: 3px;
  width: 18px;
  margin-right: .2em;
  color: #01579b
}

.location-icon.loading {
  animation: loadingPulsate .6s linear alternate infinite
}

.location-icon.error {
  color: #ca2d2d
}

input[type=text],input[type=email],input[type=password],input[type=search],textarea {
  box-sizing: border-box;
  padding: .4em 1em .5em;
  border: 1px solid #cad7de;
  border-radius: 18px
}

input[type=text]:focus,input[type=email]:focus,input[type=password]:focus,input[type=search]:focus,textarea:focus {
  outline: 0;
  border-color: #01579b
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset
}

label {
  display: block;
  margin-left: 1rem;
  margin-left: calc(1rem + 1px);
  margin-bottom: .5em;
  font-size: .8em;
  color: #969696
}

label.label-radio {
  margin-left: 0;
  font-size: 1em;
  color: inherit
}

label.label-radio input {
  margin: 0 .5em
}

select {
  padding: .3em 1em .4em;
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid #bcc2d0;
  font-size: 14px;
  cursor: pointer;
  appearance: none
}

.form-row {
  margin: 1em 0
}

.notification {
  padding: .9em 1em 1em;
  max-width: 30em;
  margin: 0 auto;
  background-color: #f3f6f7;
  color: #323232
}

.notification.notification-dismissable {
  position: relative;
  padding-right: 2.8em
}

.notification span {
  display: block
}

.notification-notice {
  background-color: #d1ebff;
  color: #01579b
}

.notification-error {
  background-color: #ffe2e2;
  color: #ca2d2d
}

.notification-success {
  background-color: #c5f5b5;
  color: #3d7529
}

.notification-dismiss {
  position: absolute;
  right: .8em;
  top: .8em;
  cursor: pointer
}

.site-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 1100px;
  min-height: 60px;
  padding: .5em 1em;
  margin: 0 auto
}

.site-branding {
  height: 22px;
  padding-left: 28px;
  color: #323232;
  font-size: 1em;
  line-height: 22px;
  background: url(/images/brand-icon.png) left center no-repeat;
  background-size: 22px 22px
}

.site-branding.minimal {
  width: 22px;
  padding-left: 0;
  color: transparent
}

.site-branding-link::after {
  display: none;
  width: 0;
  height: 0
}

.site-header--right {
  display: -ms-flexbox;
  display: flex
}

.site-header--right>* {
  margin-left: 1em
}

.site-header--right>:first-child {
  margin-left: 0
}

.app-sidebar .site-branding {
  margin: 0 auto 2em;
  width: 124px
}

.user-menu-container {
  position: relative
}

.user-menu-container.fixed {
  position: fixed;
  top: .5em;
  right: .5em;
  z-index: 10
}

.user-menu-container.fixed .header-avatar {
  box-shadow: 0 2px 2px rgba(0,0,0,.26)
}

.user-menu-container .header-avatar {
  position: relative;
  margin-right: 15px;
  cursor: pointer
}

.user-menu-container .header-avatar::after {
  position: absolute;
  top: 15px;
  right: -15px;
  bottom: auto;
  left: auto;
  display: inline-block;
  height: 0;
  width: 0;
  margin: 0;
  content: "";
  opacity: 1;
  background-color: transparent;
  border: 5px solid transparent;
  border-top: 5px solid #969696;
  transform: none
}

.user-menu-container .header-avatar:hover::after {
  border-top-color: #323232;
  transform: none
}

.user-menu {
  display: -ms-flexbox;
  display: flex;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 1000;
  top: 110%;
  top: calc(100% + .5em);
  right: -4px;
  min-width: 22em;
  background: #fff;
  box-shadow: 0 0 2px rgba(0,0,0,.12),0 2px 4px rgba(0,0,0,.24);
  font-size: .9em;
  transform: translateY(-10px);
  transition: all .4s cubic-bezier(.7,0,.3,1)
}

.user-menu.bottom-left {
  top: auto;
  right: auto;
  bottom: -4px;
  left: -4px;
  padding: .1em .1em 130%
}

.user-menu.menu-open {
  visibility: visible;
  opacity: 1;
  transform: translateY(0)
}

.user-menu-toggle {
  cursor: pointer
}

.user-menu-section {
  width: 50%;
  margin: .8em 0 1em;
  padding: 0 1em;
  border-right: 1px solid #e7edf0
}

.user-menu-section:last-child {
  border-right: 0
}

.user-menu-section-header {
  margin: 0 0 .5em
}

.user-menu-section-name {
  margin: 0;
  font-size: 1.2em
}

.user-menu-section-location {
  margin-bottom: 1em;
  font-size: 12px;
  color: #969696
}

.user-menu-section-location .location-icon {
  width: 16px;
  font-size: 16px
}

.user-menu-item {
  display: block;
  margin-bottom: .5em
}

.user-menu-item::after {
  display: none;
  width: 0;
  height: 0
}

.user-menu-item:last-child {
  margin-bottom: 0
}

.time-slider {
  width: 100%;
  height: 20px;
  margin: 1em 0;
  -ms-appearance: none;
  appearance: none;
  background: url(/images/ui/border.svg) 50% 50% repeat-x;
  cursor: pointer
}

.time-slider::-webkit-slider-thumb {
  width: 4px;
  height: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background: #597c8e
}

.time-slider:focus::-webkit-slider-thumb {
  background: #323232
}

.modal-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  height: 100%;
  width: 100%;
  background: rgba(255,255,255,.4)
}

.modal {
  position: relative;
  width: 600px;
  height: 600px;
  padding: 1em;
  background: #fff;
  box-shadow: 0 2px 2px rgba(0,0,0,.26)
}

.modal-close {
  position: absolute;
  top: .5em;
  right: .5em;
  height: .5em;
  width: .5em;
  font-size: 2em;
  line-height: .5em;
  transform: rotate(45deg);
  color: #969696
}

.modal-close::after {
  display: none;
  width: 0;
  height: 0
}

.modal-close:hover {
  color: #323232
}

.modal-header {
  margin-top: 0
}

.modal--back-button {
  position: absolute;
  top: .5em;
  left: .5em
}

.autocomplete {
  position: relative;
  transition: all .4s;
  border-radius: 2px;
  text-align: left
}

.login-form input.autocomplete--field,input.autocomplete--field {
  margin-top: 0;
  margin-bottom: 0
}

.autocomplete--results {
  position: absolute;
  top: 101%;
  top: calc(100% + 2px);
  box-shadow: 0 0 2px rgba(0,0,0,.12),0 2px 4px rgba(0,0,0,.24);
  border-radius: 16px;
  overflow: hidden;
  background: #fff
}

.autocomplete--option {
  padding: .45em 1em;
  cursor: pointer;
  white-space: nowrap
}

.autocomplete--option.selected,.autocomplete--option:hover {
  background: #f3f6f7;
  color: #01579b
}

.team-card-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.team-card {
  padding: 1em;
  margin: 0 1em 1em 0;
  width: calc(50% - 1em);
  border: 1px solid #e7edf0;
  border-radius: 2px
}

.team-card-name {
  margin: 0;
  word-wrap: break-word
}

.team-card-info {
  margin: .5em 0;
  font-size: .8em
}

.team-card-info:last-child {
  margin-bottom: 0
}

.meeting-planner-cta {
  margin: 2em 0 1em;
  padding: 4.6em .5em 1em;
  border-radius: 2px;
  color: #558ab2;
  font-size: 13px;
  text-align: center;
  background: url(/images/ui/click-avatar.png) 50% .5em no-repeat #f3f6f7;
  background-size: 48px
}

.meeting-planner-cta-text {
  margin: 0
}

.meeting-planner {
  margin: 2em auto 1em;
  padding: .5em;
  border-radius: 2px;
  background: #f3f6f7
}

.meeting-planner-suggested {
  text-align: center;
  font-size: 1.1em
}

.meeting-planner-suggested-copy {
  font-size: 12px;
  color: #969696
}

.meeting-planner-group {
  margin: .5em 0
}

.meeting-planner-group-suggested {
  margin: .1em;
  font-size: 12px;
  color: #969696
}

.meeting-planner-group-people {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.meeting-planner-group-people .avatar {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin: .1em
}

.meeting-planner-clear {
  font-size: 12px;
  text-align: center
}

.meeting-planner-clear a::after {
  display: none;
  width: 0;
  height: 0
}

.schedule {
  -ms-flex: 1 30%;
  flex: 1 30%
}

.schedule .avatar {
  margin: .4em auto
}

.schedule--hours {
  padding: 0;
  margin: 0;
  list-style: none;
  color: #5b5b5b;
  background-color: #d9e2e7
}

.schedule--hour {
  padding: .4em;
  margin: 0;
  width: 100%;
  min-height: 20px;
  background-color: transparent;
  font-size: 12px;
  line-height: 1em
}

.schedule--hour-available {
  background-color: #fff;
  color: #474747
}

.hp-headline {
  margin-top: 0;
  text-align: center;
  font-weight: 400
}

.hp-section {
  width: 100%;
  padding: 2em 0
}

.hp-section.demo {
  padding: 3em 0
}

.hp-section.demo .timezone-list {
  min-height: 264px
}

.hp-section.alt {
  background: #e7edf0
}

.hp-demo .timezone:last-of-type {
  padding-right: 0
}

.hp-content-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 16px
}

.hp-pitch-container {
  text-align: center
}

.hp-pitch {
  font-size: 1.1em;
  line-height: 1.5em;
  font-weight: 400
}

.hp-pitch-search {
  position: relative;
  display: inline-block;
  height: 30px;
  width: 60%;
  max-width: 500px;
  min-width: 240px;
  padding: .3em .6em;
  margin: 1em auto;
  text-align: left;
  background: #fff;
  border: 1px solid #ccc;
  font: 16px arial,sans-serif
}

.hp-description,.hp-description-quote {
  max-width: 580px;
  margin: 1.2em auto;
  line-height: 1.8em
}

.mc-field-group {
  margin: 1em 0
}

@media screen and (max-width: 320px) {
  body {
      font-size:14px
  }

  .hp-headline {
      padding: 0 1em
  }
}

.app-container {
  display: -ms-flexbox;
  display: flex;
  min-width: 880px
}

.app-sidebar {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 180px;
  padding: 1em;
  background: #e7edf0
}

.person-info,.view-container {
  background: #fff;
  position: absolute
}

.app-sidebar--time {
  margin: 0;
  font-size: 2em;
  text-align: center;
  font-weight: 400
}

.app-sidebar--button-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.app-sidebar--admin {
  margin: 1em 0;
  width: calc(180px - 2 * 1em)
}

.app-sidebar--admin button {
  width: 100%
}

.app-container.app-with-toolbar {
  display: block
}

.app-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: .5em 1em;
  border-bottom: 1px solid #e7edf0
}

.app-toolbar .site-branding {
  margin: 0 1em 0 0
}

.app-toolbar button.material-icons {
  height: 32px;
  width: 32px
}

.app-toolbar-section {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  width: 40%
}

.app-toolbar-center {
  width: 20%
}

@media screen and (max-width: 880px) {
  .app-toolbar-section {
      width:auto
  }
}

@media screen and (max-width: 500px) {
  .app-toolbar-center {
      display:none
  }
}

.app-toolbar-name {
  margin: 0;
  width: 100%;
  font-size: 1.1em;
  font-weight: 400;
  text-align: center
}

.team-search .team-search-input {
  height: 32px;
  padding-left: 1.2em;
  padding-right: 1.2em;
  font-size: 14px;
  border: 1px solid #e7edf0;
  border-radius: 100px
}

.app-toolbar-actions {
  display: -ms-flexbox;
  display: flex;
  width: 40%;
  -ms-flex-positive: 2;
  flex-grow: 2;
  -ms-flex-pack: end;
  justify-content: flex-end;
  min-width: 300px
}

.app-toolbar-actions>* {
  margin-left: 1rem;
  max-height: 32px
}

.app-toolbar-actions .button,.app-toolbar-actions button {
  line-height: 1.3em
}

.time-control {
  position: relative;
  display: -ms-flexbox;
  display: flex
}

.time-control .time-control-menu {
  display: none
}

.time-control.time-control-open .time-control-menu {
  display: inherit
}

.time-control-time {
  position: relative;
  z-index: 1001;
  margin: 0;
  width: 94px;
  font-size: 1.4em;
  line-height: 32px;
  font-weight: 400;
  cursor: pointer
}

.time-control-toggle {
  margin: 0 .2em
}

.time-control-toggle:focus {
  box-shadow: none
}

.time-control-slider-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center
}

.time-control-slider-container.is-hidden {
  display: none
}

.timezone-list,.timezone-wrapper {
  display: -ms-flexbox;
  max-width: 100%
}

.time-control-slider-container .time-slider {
  width: 148px;
  margin: 6px 0
}

.time-control-now {
  height: 2.1em;
  margin-left: .5em
}

.timezone-list {
  position: relative;
  z-index: 5;
  display: flex;
  height: 100%;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-positive: 1;
  flex-grow: 1;
  overflow-y: scroll
}

.person-city,.timezone-header p {
  text-overflow: ellipsis;
  overflow: hidden
}

.timezone-list-wide .timezone {
  padding-left: .8em;
  padding-right: .8em
}

.timezone-list-wide .timezone-header {
  width: 72px
}

.timezone-list-wide .timezone-time {
  font-size: 1.1em
}

.timezone-list-wide .timezone-people-column {
  min-width: auto
}

.timezone-list-wide .person {
  width: 54px;
}

.timezone-list-wide .avatar,
.timezone-list-wide .avatar-placeholder {
  height: 54px;
  width: 54px;
  line-height: 54px
}

.team-stats {
  position: absolute;
  bottom: .5em;
  left: 0;
  right: 0;
  text-align: center;
  color: #969696
}

.timezone-wrapper {
  display: flex
}

.timezone-list-active-filter .avatar,.timezone-list-active-filter .avatar-placeholder {
  filter: opacity(.3)
}

.view-container {
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 100%;
  padding: 1em
}

.manage-team--container {
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -ms-flex-align: stretch;
  align-items: stretch;
  padding: 3em 3em 0
}

.manage-team--header {
  width: 40%;
  padding-right: 2em
}

.manage-team--header-title {
  margin: 1.6em 0 1em
}

.manage-team--row {
  margin: 1em 0
}

input[type=text].manage-team--invite-url {
  width: 260px;
  color: #969696;
  font-size: .8em;
  border: 1px solid #90acba;
  border-radius: 2px;
  cursor: text
}

.manage-team--close {
  position: absolute;
  top: 0;
  right: 0
}

.manage-team--subview {
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  max-height: 100%
}

.manage-team--subview-team {
  max-height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column
}

.manage-team--team-header {
  margin-bottom: 1em;
  text-align: center
}

.manage-team--team-header input[type=search] {
  width: 315px;
  border-radius: 0
}

.manage-team--team-list {
  overflow: auto;
  padding: 0 .1em
}

.manage-team--team-member {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  padding: .5em 0
}

.manage-team--team-member-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center
}

.manage-team--team-member-name {
  margin-left: 1em
}

.manage-team--team-member-location {
  margin-left: .5em;
  color: #969696
}

.manage-team--person {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center
}

.manage-team--person .edit-person {
  margin: 2em auto 0
}

.edit-person {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center
}

.edit-person .profile-location {
  margin-bottom: 1.5em
}

.edit-person--row {
  width: 240px;
  margin: .5em 0;
  text-align: center
}

.edit-person--row input {
  width: 100%
}

.edit-person--row .avatar {
  margin: 0 auto
}

.edit-person--label {
  display: block;
  margin-right: 0;
  text-align: left
}

.edit-person--label+.button,.edit-person--label+button {
  margin-top: 1em
}

.edit-info--danger {
  width: calc(240px + 2em);
  padding: 1em;
  border: 1px solid #ffe2e2
}

.add-image-placeholder {
  height: 120px;
  width: 120px;
  margin: 0 auto;
  border-radius: 50%;
  background: #e7edf0;
  line-height: 119px;
  text-align: center
}

.edit-person--timezone-display {
  display: block;
  padding: .4em 10px;
  min-height: 2em;
  color: #969696;
  font-size: .7em;
  text-align: left
}

@media screen and (max-width: 500px) {
  .app-container {
      min-width:100%;
      height: auto;
      -ms-flex-direction: column;
      flex-direction: column
  }

  .app-toolbar {
      position: fixed;
      top: 0;
      z-index: 10;
      background: rgba(255,255,255,.9);
      width: 100%;
      padding-left: .8em;
      padding-right: .8em
  }

  .app-with-toolbar .site-branding {
      width: 22px;
      color: transparent;
      padding-left: 0
  }

  .app-with-toolbar .app-toolbar-actions,.app-with-toolbar .app-toolbar-branding {
      width: auto;
      min-width: auto
  }

  .app-with-toolbar .app-toolbar-actions>* {
      margin-left: .5em
  }

  .app-with-toolbar .app-toolbar-actions:first-child {
      margin-left: 0
  }

  .app-with-toolbar .timezone-list {
      margin-top: 60px
  }

  .app-with-toolbar .person-info {
      transform: none
  }

  .team-search input {
      width: 8em
  }

  button.manage-team-button {
      display: none
  }

  .app-sidebar {
      position: fixed;
      z-index: 1;
      width: 100%;
      padding: .8em;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -ms-flex-pack: justify;
      justify-content: space-between
  }

  .app-sidebar--format-select,.meeting-planner-cta {
      display: none
  }

  .app-sidebar .site-branding {
      margin: .2em 0 0
  }

  .app-sidebar>* {
      width: 50%
  }

  .app-sidebar--time {
      font-size: 1.5em;
      text-align: right
  }

  .time-slider-container {
      width: 40%;
      -ms-flex-order: 4;
      order: 4
  }

  .time-slider {
      margin: .9em 0 0
  }

  .app-sidebar--button-row {
      max-height: 27px;
      width: auto;
      margin-top: .7em;
      margin-bottom: 0;
      -ms-flex-pack: start;
      justify-content: flex-start
  }

  .timezone-list,.timezone-wrapper {
      -ms-flex-pack: start;
      justify-content: flex-start;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse
  }

  .team-stats {
      position: relative;
      padding: 1em 0;
      -ms-flex-order: -1;
      order: -1
  }

  .timezone,.timezone-list-wide .timezone {
      display: -ms-flexbox;
      display: flex;
      width: auto;
      padding: 0;
      margin: .8em .8em 0
  }

  .timezone-header {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      margin: 0 .8em 0 0
  }

  .timezone-people {
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-positive: 1;
      flex-grow: 1
  }

  .timezone-people-column {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin: 0
  }
}

.page-headline,.profile-details,.profile-edit,.site-footer {
  text-align: center
}

.profile {
  padding-top: 0
}

.profile-main {
  max-width: 800px;
  margin: 0 auto
}

.profile-avatar {
  margin: .5em auto
}

.profile-name {
  margin: .3em 0;
  font-size: 32px;
  font-weight: 400
}

.profile-location {
  margin: 0
}

.profile-location-clickable {
  cursor: pointer
}

.profile-offset {
  margin-left: .8em;
  color: #969696;
  font-size: .8em;
  line-height: 1em
}

.profile-row {
  margin: 2em 0
}

.profile-team {
  margin: 0 .1em
}

.profile-avatar-options {
  margin: 1em 0;
  text-align: center
}

.profile-avatar-options>.button,.profile-avatar-options>span {
  margin: 0 .2em;
  vertical-align: middle
}

.profile-edit-form {
  margin-top: 2em
}

.profile-edit-form .autocomplete {
  display: inline-block
}

.profile-edit-form .edit-person--timezone-display {
  max-width: 364px;
  margin: auto
}

.profile-use-location-button {
  margin-left: 1em
}

@keyframes loadingPulsate {
  0% {
      color: #01579b
  }

  100% {
      color: #f3f6f7
  }
}

.login-form {
  width: 20em;
  margin: 2em auto
}

.login-form .login-button,.login-form input {
  width: 100%;
  margin: .5em 0
}

.login-form .login-button {
  padding: .5em 1em .6em
}

.form-copy {
  margin: .5em .6em;
  font-size: .8em
}

.site-footer {
  padding: 1px 0;
  background: #fff
}

.about-page,.roadmap-page {
  background-size: cover;
  background-position: center
}

.footer-link {
  margin-right: 1.5em
}

.footer-link:last-child {
  margin-right: 0
}

.about-page {
  background-image: url(/images/wallpaper/outside-window.jpg)
}

.roadmap-page {
  background-image: url(/images/wallpaper/pch-driving.jpg)
}

.roadmap-list {
  margin: 1em 0
}

.roadmap-item {
  max-width: 400px;
  padding: .5em 1em;
  margin: .5em auto;
  background: #fff;
  font-size: 1.1em;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0,0,0,.1)
}

.page-content {
  max-width: 30em;
  padding: 1em;
  margin: 0 auto
}

.admin-content {
  margin: 0 auto;
  max-width: 600px
}

.admin-section {
  margin: 1em 0
}

.admin-search-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.admin-list--item {
  display: -ms-flexbox;
  display: flex;
  margin: .5em 0;
  padding: .5em 1em .6em;
  background: #f3f6f7;
  border-radius: 2px
}

.admin-list--name {
  -ms-flex-positive: 2;
  flex-grow: 2
}

.admin-list--action {
  margin-left: 6px
}

.account-container {
  width: 100%
}

.account-container input[type=text],.account-container input[type=email],.account-container input[type=password] {
  min-width: 300px
}

.account-container textarea {
  min-width: 500px
}

.error-stack-line {
  font-size: .8em;
  margin-left: 3em;
  text-indent: -1em
}

.error-stack-line:first-of-type {
  margin-left: 0;
  text-indent: 0
}
