.timezone {
  margin: 1em;
  padding: 0.4em;
  padding: 1em;
}
.timezone-current {
  background-color: #F8F8FA;
  border-radius: 0.5em;
}

.timezone-people {
  display: -ms-flexbox;
  display: flex
}

.timezone-people-column {
  min-width: 60px;
  margin: 0 auto 0 0.8em;
}
.timezone-people-column:first-child {
  margin-left: 0
}
.timezone-people-column:only-child {
  margin-left: auto;
}

/* Greyed out for nighttime */
.timezone-hour-0 .avatar,
.timezone-hour-1 .avatar,
.timezone-hour-2 .avatar,
.timezone-hour-22 .avatar,
.timezone-hour-23 .avatar,
.timezone-hour-3 .avatar,
.timezone-hour-4 .avatar,
.timezone-hour-5 .avatar,
.timezone-hour-6 .avatar,
.timezone-hour-7 .avatar {
  filter: grayscale(50%) brightness(.8)
}

.timezone-header {
  margin: 0 auto .5em;
  width: 80px;
  text-align: center;
  white-space: nowrap
}

.timezone-header p {
  margin: 5px 0
}

.timezone-time {
  margin: 0 0 0.4em;
  font-weight: 400
}

.timezone-name {
  font-size: .6em;
  text-transform: uppercase;
  font-weight: 700;
  /* color: #5b5b5b; */
}

.timezone-offset {
  margin: 0;
  font-size: .7em;
  /* color: #61676f; */
}
