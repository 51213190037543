.person {
  position: relative;
  margin: .8em 0;
  width: 60px; /* default avatar width */
  /* cursor: pointer */
}

.person .avatar,
.person .avatar-placeholder {
  transition: filter .4s,-moz-filter .4s
}

.person-highlight .avatar,
.person-highlight .avatar-placeholder {
  filter: opacity(1)
}

.person-name {
  max-width: 100%;
  padding: .8em 0 0;
  max-height: 3em;
  font-size: .8em;
  text-align: center;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.person-info {
  left: 50%;
  bottom: -2em;
  z-index: 100;
  min-width: 100%;
  max-height: 3em;
  margin: 0 auto;
  padding: .3em .4em .4em;
  transform: translateX(-50%);
  opacity: 0;
  transition: all .2s;
  border-radius: 2px;
  font-size: .8em;
  font-weight: 700;
  text-align: center
}

.person-info.person-info-short {
  bottom: -1em
}

.person-info p {
  margin: 0;
  line-height: 1.1em;
  white-space: nowrap
}

.person-info .person-city {
  margin-top: .3em
}

.person-city {
  font-size: .8em;
  font-weight: 400;
  white-space: nowrap
}

.person-highlight .person-info,
.person:hover .person-info {
  opacity: 1;
  z-index: 110
}

@media screen and (max-width: 500px) {
  .person {
    width: 25%;
    margin: 0 0 .5em
  }

  .person-info {
      display: block;
      opacity: 1;
      position: inherit;
      z-index: inherit;
      left: auto;
      right: auto;
      width: auto;
      padding: 0;
      bottom: auto;
      box-shadow: none;
      font-weight: 400
  }

  .person-info .person-name {
      margin: .3em 0 0
  }

  .person-city {
      display: none
  }
}
